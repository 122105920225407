$fontstack: 'Poppins', sans-serif;
$fontbody: 'Open Sans', sans-serif;
$colordefault: #000;
$colorblue: #232c46;
$colororange: #d95240;

@mixin transition($second) {
  -webkit-transition: all $second ease-in-out;
  -moz-transition: all $second ease-in-out;
  transition: all $second ease-in-out;
}